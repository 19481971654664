/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Box, CircularProgress } from "@mui/material";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

import ProfileInfoCard from "examples/Cards/InfoCards2/ProfileInfoCard";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DataTable from "examples/Tables/DataTable";

// import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import dataTableData from "layouts/events/event-details/data/dataTableData";
import dataTableData2 from "layouts/events/event-details/data/dataTableData2";

import Header from "layouts/pages/profile/components2/Header";
import PlatformSettings from "layouts/events/event-details/components/PlatformSettings";
import PlatformSettings2 from "layouts/events/event-details/components/PlatformSettings2";

import Divider from "@mui/material/Divider";

import { useParams } from "react-router-dom";
import { oneEventStatistiquesApi } from "api/statistiques";
import { getOneEventApi } from "api/events";
import { Key } from "@mui/icons-material";

function EventsDetails() {
  const [isLoading, setIsLoading] = useState(false)
  const { eventID } = useParams()

  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);

  const [eventStatiquesDetails, setEventStatiquesDetails] = useState(null)
  const [eventDetails, setEventDetails] = useState(null)

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      setIsLoading(true)

      const eventDtails1 = await oneEventStatistiquesApi(eventID)
      setEventStatiquesDetails(eventDtails1)

      const eventDtails2 = await getOneEventApi(eventID)
      setEventDetails(eventDtails2)

      Object.entries(eventDtails1?.eventCategoriesDetails || {}).forEach(([key, value]) => {
        console.log(`Category: ${key}`, value);
      })
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings />
              </Grid> */}
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  // title="Informations"
                  // description="Hi, I'm Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={{
                    // "Nom et prénom": "Alec M. Thompson",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <PlatformSettings2 eventStatiquesDetails={eventStatiquesDetails} />
              </Grid>
            </Grid>
          </MDBox>
        </Header>

        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Nombre de tickets vendus"
                count={eventStatiquesDetails?.numberOfSellTickets}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Nombre d'invitations"
                count={eventStatiquesDetails?.numberOfInvitation}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Nombre de réservations"
                count={eventStatiquesDetails?.numberOfReservation}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Tickets disponibles"
                count={eventStatiquesDetails?.availableTickets}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Total des tickets"
                count={eventStatiquesDetails?.sumOfTickets}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Chiffre d'affaires"
                count={`${eventStatiquesDetails?.turnOver} MAD`}
                percentage={{
                  color: "success",
                  value: "",
                  label: "",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Les tickets
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
              <Table aria-label="simple table">
              <TableHead style={{ display: "table-header-group", important: "true" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Siège
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Tickets vendus
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Invitations
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Tickets hors ligne
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Tickets en ligne
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Prix unitaire
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Prix total
                  </TableCell>
                </TableRow>
              </TableHead>
                {
                  isLoading || !eventStatiquesDetails?.eventCategoriesDetails ?
                    <MDBox>
                      <CircularProgress size={80} color="info"></CircularProgress>
                    </MDBox> :
                    <TableBody>
                      {
                        Object.entries(eventStatiquesDetails?.eventCategoriesDetails || {}).map(([key, value]) => {
                          return (
                            <TableRow key="index" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{key === "sumOfValues" ? "Total" : key}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.numberOfSellTickets}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.numberOfInvitation}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.offlineTickets}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.onlineTickets}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.unityPrice}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color: "#7b809a",
                                  fontSize: "0.8rem",
                                  fontWeight: "600",
                                  maxWidth: 100,
                                }}
                              >
                                <p>{value.calculatedCategoryTurnOver} MAD</p>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                }
            </Table>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Revendeurs
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData2}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventsDetails;
