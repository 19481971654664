/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Swal from "sweetalert2";

import { SpeedDial, SpeedDialAction, Grid, CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";

import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import ResetIcon from "@mui/icons-material/Restore";

// Data
import { getAllPlacesApi } from "api/places";
import TicketModal from "./components/modals/ticket-modal";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useSocket } from "context/socketContext";

import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { getAllEventApi } from "api/events";
import { getAllTicketsApi, resetBuyInfoApi, resetScanInfoApi, downloadTicketApi, deleteTicketApi } from "api/tickets";
import API_URL from "config";

function TicketList() {
  const socket = useSocket();

  const [isLoading, setIsLoading] = useState(false)

  const [menu, setMenu] = useState(null);
  const [tableRowData, setTableRowData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalItemData, setOpenModalItemData] = useState("");

  const [formDataValues, setFormDataValues] = useState({});

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    socket.on("newWebsiteOrder", async (message) => {
      getTableData();
    });
    socket.on("ticketIsScanned", async (message) => {
      getTableData();
  });

    return () => {
      socket.off("newWebsiteOrder"); // Clean up listener
      socket.off("ticketIsScanned"); // Clean up listener
    };
  }, [socket]);

  const handleCloseModal = (params) => {
    setOpenModal(false);
  };

  const handleEditeModal = (Data) => {
    setOpenModalItemData(Data);
    setOpenModal(true);
  };

  const getTableData = async () => {
    try {
      const eventResponse = await getAllEventApi();
      const eventDataArray = eventResponse.map((el) => ({
        title: el.eventTitle,
        id: el._id,
      }));
      setEventData(eventDataArray);

      const tableData = await getAllTicketsApi(formDataValues.eventId);
      setTableRowData(tableData.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteItem = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer",
        icon: "warning",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // delete ticket
            await deleteTicketApi(ID)

            const tableData = await getAllTicketsApi(formDataValues.eventId);
            setTableRowData(tableData.data);

            Swal.fire("Supprimée", "", "success");
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshData = () => {
    getTableData();
  };

  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);
  };

  const handleAutoCompleteEventChange = async (event, newValue) => {
    try {
      const newFormDataValues = {
        ...formDataValues,
        eventId: newValue ? newValue?.id : "",
      };

      setFormDataValues(newFormDataValues);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterDataBtn = async () => {
    try {
      setIsLoading(true)

      const tableData = await getAllTicketsApi(formDataValues.eventId);
      setTableRowData(tableData.data);
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleResetBuyInfo = async (ticketID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment réinitialiser",
        icon: "warning",
        confirmButtonText: "Réinitialiser",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setIsLoading(true)
            await resetBuyInfoApi(ticketID)

            const tableData = await getAllTicketsApi(formDataValues.eventId);
            setTableRowData(tableData.data);

            Swal.fire("Réinitialisée", "", "success");
          } catch (err) {
            console.log(err);
          }
          finally {
            setIsLoading(false)
          }
        }
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleResetScanInfo = async (ticketID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment réinitialiser",
        icon: "warning",
        confirmButtonText: "Réinitialiser",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setIsLoading(true)
            await resetScanInfoApi(ticketID)

            const tableData = await getAllTicketsApi(formDataValues.eventId);
            setTableRowData(tableData.data);

            Swal.fire("Réinitialisée", "", "success");
          } catch (err) {
            console.log(err);
          }
          finally {
            setIsLoading(false)
          }
        }
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleDownloadTicket = async (id) => {
    try {
      const url = await downloadTicketApi(id)
      window.open(url, '_blank')
    }
    catch (err) {
      console.log(err);
    }
  }

  const handlePrintTicket = async (id) => {
    try {
      window.open(`${API_URL}/api/V2/pdf/ticket/print/${id}`, '_blank')
    }
    catch (err) {
      console.log(err);
    }
  }

  const renderMenu2 = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Online</MenuItem>
      <MenuItem onClick={closeMenu}>Offline</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <TicketModal
        openModal={openModal}
        closeModal={handleCloseModal}
        refreshData={handleRefreshData}
        isEdite={true}
        openModalItemData={openModalItemData}
      />
      <DashboardNavbar />
      <MDBox my={3}>
      <Grid container spacing={3} alignItems="center" mb={4}>
          <Grid item xs={12} lg={4}>
            {/* <MDInput type="text" label="Événement" size="large" fullWidth /> */}
            <Autocomplete
              // multiple
              // value={selectedEvent}
              options={eventData}
              getOptionLabel={(option) => option?.title || ""}
              onChange={handleAutoCompleteEventChange}
              renderInput={(params) => <FormField {...params} label="Événement" />}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDInput type="date" label="Date" size="large" fullWidth onChange={handleFilterInputChange} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDInput type="text" label="Client" size="large" fullWidth onChange={handleFilterInputChange} />
          </Grid>
          <Grid item xs={12} lg={4}>
          <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu} fullWidth>
            Type de réservation
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu2}
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDInput type="text" label="Référence" size="large" fullWidth onChange={handleFilterInputChange} />
          </Grid>
      </Grid>
      <Grid container alignItems="center" mb={4}>
        <Grid item xs={12} lg={4}>
          <MDButton variant="gradient" color="light" onClick={handleFilterDataBtn}>Filtrer</MDButton>
        </Grid>
      </Grid>
        <Card>
          <TableContainer component={Paper}>
            <MDBox
              width="15rem"
              ml="auto"
              display="flex"
              justifyContent="right"
              padding={2}
            ></MDBox>
            <Table aria-label="simple table">
              <TableHead style={{ display: "table-header-group", important: "true" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Référence du ticket
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Événement
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Siège
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Client
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Prix
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Statut
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
                {
                  isLoading ?
                  <MDBox>
                    <CircularProgress size={80} color="info"></CircularProgress>
                  </MDBox> :
                  <TableBody>
                    {
                      tableRowData.map((el, index) => (
                        <TableRow key="index" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p> {el.ticketID} </p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p> {el.eventID.eventTitle} </p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p> {el.selectedSiegeName} </p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p> {el.userInfo.firstName} {el.userInfo.lastName} </p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p> {el.price} MAD </p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            {
                              <StatusCell
                                icon={el.isUsed ? "done" : "syncIcon"}
                                color={el.isUsed ? "success" : "warning"}
                                status={el.isUsed ? "utilisée" : "En attente"}
                              />
                            }
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                              <SpeedDial
                                ariaLabel="SpeedDial basic example"
                                sx={{ position: "absolute", top: 0, right: "60%" }}
                                className="custom-speed-dial"
                                icon={<MoreIcon />}
                                direction="left"
                              >
                                <SpeedDialAction
                                  key={""}
                                  icon={<EmailIcon />}
                                  tooltipTitle="Envoyer par mail"
                                  onClick={() => handleEditeModal(el)}
                                />
                                <SpeedDialAction
                                  key={""}
                                  icon={<DownloadIcon />}
                                  tooltipTitle="Télécharger"
                                  onClick={() => handleDownloadTicket(el._id)}
                                />
                                <SpeedDialAction
                                  key={""}
                                  icon={<PrintIcon />}
                                  tooltipTitle="Imprimer"
                                  onClick={() => handlePrintTicket(el._id)}
                                />
                                {/* <SpeedDialAction
                                  key={""}
                                  icon={<ResetIcon />}
                                  tooltipTitle="Réinitialiser les informations d'achat"
                                  onClick={() => handleResetBuyInfo(el._id)}
                                /> */}
                                <SpeedDialAction
                                  key={""}
                                  icon={<ResetIcon />}
                                  tooltipTitle="Réinitialiser les informations de scan"
                                  onClick={() => handleResetScanInfo(el._id)}
                                />
                                <SpeedDialAction
                                  key={""}
                                  icon={<DeleteIcon />}
                                  tooltipTitle="Supprimer"
                                  onClick={() => handleDeleteItem(el._id)}
                                />
                              </SpeedDial>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                }
            </Table>
          </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TicketList;
