/*
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewEvent page components
import EventImage from "./components/EventImage";
import FormFields from "./components/FormFields";
import EventDescription from "./components/Description";
import EventImageCover from "./components/EventImageCover";
import { updateEventApi, updateEventImgsApi } from "api/events";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import EventImageSieges from "./components/EventImageSieges";
import EventImages from "./components/EventImages";

function UpdateEvent() {
  const { eventID } = useParams()
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    eventMainImg: null,
    eventCoverImg: null,
    eventSiegesImg: null,
    otherEventImgs: null,
    description: "",
    eventInfo: {},
  });
  const [newEventLoading, setNewEventLoading] = useState(false)

  // useEffect(() => {
  //   console.log("formValue changed:", formValue);
  // }, [formValue]);

  const handleEventMainImg = async (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventMainImg: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleEventCoverImg = async (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventCoverImg: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleEventSiegeImg = async (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventSiegesImg: value,
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleEventImgs = async (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        otherEventImgs: value,
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleUpdateEvent = async () => {
    try {
      setNewEventLoading(true)

      const eventImgObject = {
        eventMainImg: formValue.eventMainImg,
        eventCoverImg: formValue.eventCoverImg,
        eventSiegesImg: formValue.eventSiegesImg,
        otherEventImgs: formValue.otherEventImgs
      }

      const removeIdFromArray = (arr) => {
        if (Array.isArray(arr)) {
          return arr.map(obj => {
            const { _id, ...rest } = obj;  // Destructure and remove _id from each object
            return rest;
          });
        }
        return arr;  // Return unchanged if not an array
      };

      const eventObject = {
        eventTitle: formValue?.eventInfo?.eventTitle || "",
        promoVideo: formValue?.eventInfo?.promoVideo || "",
        address: formValue?.eventInfo?.address || "",
        description: formValue?.description || "",
        eventCategory: formValue?.eventInfo?.eventCategory?.length > 0 ? formValue?.eventInfo?.eventCategory.map(el => el._id) : [],
        eventPlace: removeIdFromArray(formValue?.eventInfo?.eventPlace),
        eventSiege: removeIdFromArray(formValue?.eventInfo?.eventSiege),
        eventStand: removeIdFromArray(formValue?.eventInfo?.eventStand),
        socialLink: removeIdFromArray(formValue?.eventInfo?.socialLink),
        startDate: `${formValue?.eventInfo?.startDate}T${formValue?.eventInfo?.startTime}` || "",
        endDate: `${formValue?.eventInfo?.endDate}T${formValue?.eventInfo?.endTime}` || "",
        eventDate: `${formValue?.eventInfo?.eventDate}T${formValue?.eventInfo?.eventTime}` || "",
        eventCloseDoorDate: `${formValue?.eventInfo?.eventDoorClosingDate}T${formValue?.eventInfo?.eventDoorClosingTime}` || "",
        onlineReservation: formValue?.eventInfo?.onlineReservation,
        isSelectetivePlaces: formValue?.eventInfo?.isSelectetivePlaces,
        seatIoId: formValue?.eventInfo?.seatIoId,
      }

      if(!eventImgObject?.eventMainImg || !eventImgObject?.eventCoverImg || eventImgObject?.eventMainImg == "" || eventImgObject?.eventCoverImg == "") {
        return Swal.fire({
          title: "Selectionner la photo principal et la photo de miniature",
          text: "",
          icon: "error"
        });
      }

      if(!eventImgObject.eventMainImg.type.startsWith("image/")) {
        return Swal.fire({
          title: "Selectionner une fichier de type image pour Image principale d'événement",
          text: "",
          icon: "error"
        });
      }
      if(parseFloat(eventImgObject.eventMainImg.size) / (1024*1024) > 5) {
        return Swal.fire({
          title: "la taille de l'image principal doit être inférieure de 5 MB",
          text: "",
          icon: "error"
        });
      }

      if(!eventImgObject.eventCoverImg.type.startsWith("image/")) {
        return Swal.fire({
          title: "Selectionner une fichier de type image pour Image de coverture d'événement",
          text: "",
          icon: "error"
        });
      }
      if(parseFloat(eventImgObject.eventCoverImg.size) / (1024*1024) > 5) {
        return Swal.fire({
          title: "la taille de l'image de coverture doit être inférieure de 5 MB",
          text: "",
          icon: "error"
        });
      }

      console.log("formValue?.eventInfo", formValue?.eventInfo)

      await updateEventImgsApi(eventImgObject, eventID)
      await updateEventApi(eventObject, eventID)

      Swal.fire({
        title: "Modifiée",
        text: "",
        icon: "success"
      });

      navigate("/evenements/voir");
    } catch (err) {
      Swal.fire({
        title: err.response.data.error,
        text: "",
        icon: "error"
      });
    }
    finally {
      setNewEventLoading(false)
    }
  };

  const handleDescriptionValue = (value) => {
    try {
      if (formValue.description !== value) {
        // Only update state if the value is different
        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          description: value,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetFormValues = (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventInfo: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Modifier cette événement
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text"></MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                {
                  newEventLoading ?
                  <MDBox display="flex" justifyContent="center" alignItems="center" mt={5} mb={5}>
                  <CircularProgress color="info" size={50} />
                  </MDBox>
                  :
                  <MDButton variant="gradient" color="info" onClick={handleUpdateEvent}>
                  Modifier
                </MDButton>
                }
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={3}>
            <EventImage imgValue={handleEventMainImg} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <EventImageCover imgCoverValue={handleEventCoverImg} />
          </Grid>
          <Grid item xs={12} lg={6} mt={5}>
            <EventImageSieges imgSiegeValue={handleEventSiegeImg} />
          </Grid>
          <Grid item xs={12} lg={6} mt={5}>
            <EventImages imgValues={handleEventImgs} />
          </Grid>
          <Grid item xs={12}>
            {/* <EventDescription descriptionValue={handleDescriptionValue} /> */}
            <EventDescription descriptionValue={handleDescriptionValue} />
          </Grid>
          <Grid item xs={12}>
            <FormFields formValues={handleGetFormValues} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateEvent;
