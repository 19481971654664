export const formatedDate = (date) => {
    return `
        ${new Date(date).getFullYear()}-${(new Date(date).getMonth()+1).toString().padStart(2, "0")}-${new Date(date).getDate().toString().padStart(2, "0")}
    `
}

export const formatedTime = (date) => {
    return `
        ${(new Date(date).getHours() -1).toString().padStart(2, "0")}:${new Date(date).getMinutes().toString().padStart(2, "0")}
    `
}
