/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";

const dataTableData3 = {
  columns: [
    { Header: "événement", accessor: "event" }, // width: "50%"
    { Header: "date", accessor: "date" },
    { Header: "chiffre d'affaire", accessor: "turnover", align: "center" },
    { Header: "pourcentage de la vente", accessor: "eventPercent", align: "center" },
    { Header: "état de l'événement", accessor: "eventStatus", align: "center" },
  ],

  rows: [
    {
      event: <DefaultCell>BOUDCHART AGADIR - 20 Août 2024</DefaultCell>,
      date: <DefaultCell>15-02-2024</DefaultCell>,
      turnover: <DefaultCell>500 000 Dhs</DefaultCell>,
      eventPercent: <DefaultCell>38%</DefaultCell>,
      eventStatus: <DefaultCell>500</DefaultCell>
    },
    {
      event: <DefaultCell>ELGRANDETOTO en Live concert à Agadir 'Twenty-Seven Tour'</DefaultCell>,
      date: <DefaultCell>15-03-2024</DefaultCell>,
      turnover: <DefaultCell>500 00 Dhs</DefaultCell>,
      eventPercent: <DefaultCell>20%</DefaultCell>,
      eventStatus: <DefaultCell>500</DefaultCell>
    },
    {
      event: <DefaultCell>IMGHRANE GUEST : NAJAT AATABOU - HAJIB - LAARBI IMGHRANE - HAMID EL KASRI</DefaultCell>,
      date: <DefaultCell>15-04-2024</DefaultCell>,
      turnover: <DefaultCell>500 000 Dhs</DefaultCell>,
      eventPercent: <DefaultCell>50%</DefaultCell>,
      eventStatus: <DefaultCell>500</DefaultCell>
    },
    {
      event: <DefaultCell>BOUDCHART</DefaultCell>,
      date: <DefaultCell>15-05-2024</DefaultCell>,
      turnover: <DefaultCell>500 000 Dhs</DefaultCell>,
      eventPercent: <DefaultCell>80%</DefaultCell>,
      eventStatus: <DefaultCell>500</DefaultCell>
    },
    {
      event: <DefaultCell>Soirée AJYAL 'IZENZAREN, IMGHRAN, JUBANTUJA'</DefaultCell>,
      date: <DefaultCell>15-06-2024</DefaultCell>,
      turnover: <DefaultCell>500 000 Dhs</DefaultCell>,
      eventPercent: <DefaultCell>70%</DefaultCell>,
      eventStatus: <DefaultCell>500</DefaultCell>
    },
  ],
};

export default dataTableData3;
