/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewEvent page components
import FormFields from "./components/FormFields";
import { addNewMediaImgsApi } from "api/medias";

import { useNavigate } from "react-router-dom";
import { getAllEventApi } from "api/events";
import { addNewReservationApi } from "api/tickets";
import { CircularProgress } from "@mui/material";

function NewReservation() {
  const navigate = useNavigate();

  useEffect(() => {
    getInitialData();
  }, []);

  const [eventData, setEventData] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const getInitialData = async () => {
    try {
      const eventResponse = await getAllEventApi();
      const eventDataArray = eventResponse.map((el) => ({
        title: el.eventTitle,
        id: el._id,
        eventSiege: el.eventSiege,
        isSelectetivePlaces: el.isSelectetivePlaces,
        seatIoId: el.seatIoId
      }));
      setEventData(eventDataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetFormValues = (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        formInfo: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetSelectedPlaceEvent = (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        seatInfo: value,
      }));
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAddNewTickets = async () => {
    try {
      setIsLoading(true)

      const selectedCategoriesFromSeat = []
      const objectFormValues = {
        fromEvent: formValue?.formInfo?.fromEvent,
        ticketType: formValue?.formInfo?.ticketType,
        firstName: formValue?.formInfo?.firstName,
        lastName: formValue?.formInfo?.lastName,
        email: formValue?.formInfo?.email,
        phone: formValue?.formInfo?.phone,
        couponCode: formValue?.formInfo?.couponCode,
        selectedCategory: formValue?.formInfo?.selectedCategory,
        numberOfTickets: formValue?.formInfo?.numberOfTickets,
        selectedPlaces: []
      }

      if(formValue?.formInfo?.isSelectetivePlaces) {
        formValue?.seatInfo.map(el => {
          selectedCategoriesFromSeat.push(el?.category?.label)
          objectFormValues.selectedPlaces.push(el.id)
        })

        if(!objectFormValues.selectedPlaces || objectFormValues.selectedPlaces.length == 0) {
          return Swal.fire({
            title: "selectionner une place",
            text: "",
            icon: "error"
          });
        }

        const uniqueSelectedCategoriesFromSeat = [...new Set(selectedCategoriesFromSeat)]
        if(uniqueSelectedCategoriesFromSeat.length > 1) {
          return alert('Choose one category')
        }
        const selectedCategory = formValue?.formInfo?.availableCategories.find(el => el.name == uniqueSelectedCategoriesFromSeat[0])
        objectFormValues.selectedCategory = selectedCategory.id
      }

      await addNewReservationApi(objectFormValues)

      Swal.fire({
        title: `Ajoutée`,
        text: "",
        icon: "success"
      });

      navigate(`/reservations/reservations`);
    } catch (err) {
      if(err?.response?.data?.seatIoError) {
        return Swal.fire({
          title: err?.response?.data?.seatIoError,
          text: "",
          icon: "error"
        });
      }
      Swal.fire({
        title: err?.response?.data?.error || "Veuillez remplir tous les champs",
        text: "",
        icon: "error"
      });
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Ajouter une réservation
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text"></MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                {
                  isLoading ?
                  <MDBox>
                    <CircularProgress size={40} color="info"></CircularProgress>
                  </MDBox> :
                  <MDButton variant="gradient" color="info" onClick={handleAddNewTickets}>
                    Ajouter
                  </MDButton>
                }
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormFields formValues={handleGetFormValues} eventData={eventData} selectedPlaceEvent={handleGetSelectedPlaceEvent} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewReservation;
