/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Swal from "sweetalert2";

import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { SpeedDial, SpeedDialAction, Grid, CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";

import MoreIcon from "@mui/icons-material/More";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import InfoIcon from "@mui/icons-material/Info";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";

// Data
import { getAllPlacesApi } from "api/places";

import { getAllEventApi } from "api/events";
import { getAllReservationApi } from "api/tickets";

import ReservationModal from "./components/modals/reservation-modal";
import { formatedDate } from "utils/formattedDate";
import { formatedTime } from "utils/formattedDate";
import { getOneReservationDetails } from "api/tickets";
import { sendCommandeTicketsToMailApi } from "api/tickets";

function ReservationList() {
  const [isLoading, setIsLoading] = useState(false)

  const [menu, setMenu] = useState(null);
  const [tableRowData, setTableRowData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalItemData, setOpenModalItemData] = useState({});

  const [formDataValues, setFormDataValues] = useState({
    commandeId: "",
    email: ""
  });

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTableData();
  }, []);

  const handleCloseModal = (params) => {
    setOpenModal(false);
  };

  const handleCommandeDetailsModal = async (id) => {
    const reservationDetails = await getOneReservationDetails(id)
    setOpenModalItemData(reservationDetails)
    setOpenModal(true)
  };

  const getTableData = async () => {
    try {
      const eventResponse = await getAllEventApi();
      const eventDataArray = eventResponse.map((el) => ({
        title: el.eventTitle,
        id: el._id,
      }));
      setEventData(eventDataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);
  };
  const handleAutoCompleteEventChange = async (event, newValue) => {
    try {
      const newFormDataValues = {
        ...formDataValues,
        eventId: newValue ? newValue?.id : "",
      };

      setFormDataValues(newFormDataValues);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterDataBtn = async () => {
    try {
      setIsLoading(true)

      const tableData = await getAllReservationApi(formDataValues);
      setTableRowData(tableData.data)
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleSendTicketsToEmail = async (id) => {
    try {
      setIsLoading(true)
      const reservationDetails = await getOneReservationDetails(id)
      await sendCommandeTicketsToMailApi(reservationDetails)
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleDeleteItem = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer",
        icon: "warning",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // deleteItem api
            Swal.fire("Supprimée", "", "success");
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Online</MenuItem>
      <MenuItem onClick={closeMenu}>Hors ligne</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const renderMenu2 = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Payée</MenuItem>
      <MenuItem onClick={closeMenu}>En attente</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <ReservationModal
        openModal={openModal}
        closeModal={handleCloseModal}
        openModalItemData={openModalItemData}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}></MDBox> */}
        <Grid container spacing={3} alignItems="center" mb={4}>
          <Grid item xs={12} lg={4}>
            {/* <MDInput type="number" label="Evénement" size="large" fullWidth /> */}
            <Autocomplete
              // multiple
              // value={selectedEvent}
              options={eventData}
              getOptionLabel={(option) => option?.title || ""}
              onChange={handleAutoCompleteEventChange}
              renderInput={(params) => <FormField {...params} label="Événement" />}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDInput type="text" label="Email" size="large" name="email" fullWidth onChange={handleFilterInputChange} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDInput type="text" label="Référence" size="large" name="commandeId" fullWidth onChange={handleFilterInputChange} />
          </Grid>
        </Grid>

        <Grid container alignItems="center" mb={4}>
          <Grid item xs={12} lg={4}>
            <MDButton variant="gradient" color="light" onClick={handleFilterDataBtn}>Filtrer</MDButton>
          </Grid>
        </Grid>

        <Card>
          <TableContainer component={Paper}>
            <MDBox
              width="15rem"
              ml="auto"
              display="flex"
              justifyContent="right"
              padding={2}
            ></MDBox>
            <Table aria-label="simple table">
              <TableHead style={{ display: "table-header-group", important: "true" }}>
                <TableRow>
                <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Nom et prénom
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    N° de téléphone
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
                {
                  isLoading ?
                  <MDBox>
                    <CircularProgress size={80} color="info"></CircularProgress>
                  </MDBox> :
                  <TableBody>
                    {
                      tableRowData.map((el) => (
                        <TableRow key="index" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p>{formatedDate(el?.createdAt)} {formatedTime(el?.createdAt)}</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p>{ el?.userInfo?.firstName } { el?.userInfo?.lastName }</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p>{ el?.userInfo?.phone }</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <p>{ el?.userInfo?.email }</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#7b809a",
                              fontSize: "0.8rem",
                              fontWeight: "600",
                              maxWidth: 100,
                            }}
                          >
                            <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                              <SpeedDial
                                ariaLabel="SpeedDial basic example"
                                sx={{ position: "absolute", top: 0, right: "60%" }}
                                className="custom-speed-dial"
                                icon={<MoreIcon />}
                                direction="left"
                              >
                                <SpeedDialAction
                                  key={""}
                                  icon={<SendIcon />}
                                  tooltipTitle="Envoyer par mail"
                                  onClick={() => handleSendTicketsToEmail(el._id)}
                                />
                                <SpeedDialAction
                                  key={""}
                                  icon={<InfoIcon />}
                                  tooltipTitle="Détails"
                                  onClick={() => handleCommandeDetailsModal(el._id)}
                                />
                                <SpeedDialAction
                                  key={""}
                                  icon={<DeleteIcon />}
                                  tooltipTitle="Annuler"
                                  onClick={() => handleDeleteItem()}
                                />
                              </SpeedDial>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }

                  </TableBody>
                }
            </Table>
          </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReservationList;
