/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";

function FormFields({ formValues, eventData, selectedPlaceEvent }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [formDataValues, setFormDataValues] = useState({
    fromEvent: "",
    ticketType: "",

    firstName: "",
    lastName: "",
    email: "",
    phone: "",

    couponCode: "",
    availableCategories: [],
    selectedCategory: "",
    selectedCategoryPrice: 0,
    numberOfTickets: 0,
    pricing: [],

    sumOfPrice: 0,

    isSelectetivePlaces: false,
    seatIoId: ""
  });

  const [selectedPlaces, setSelectedPlaces] = useState([])

  // seatio methods
  const customColors = {
    availableObjectColor: "#6C096C",
    selectedObjectColor: "#00FF00"
  }
  const handleChartRendered = (chartManager) => {
    // setChartRendered(true);
    // console.log("chartManager", chartManager)
    // chartManager.initialContainerDimensions.width = 1200
    // chartManager.domElementListener.lastDimensions.width = 1200
  }
  const objectPopoverParams = {
    showAvailability: true,
    showCategory: true,
    showLabel: true,
    showPricing: true,
    showUnavailableNotice: true,
    stylizedLabel: true,
    confirmSelection: "never" // always - never
  }
  const handleSelectedObject = (object) => {
    console.log("object", object.pricing.price)
    // Add the object to the selectedPlaces array if it's not already present
    setSelectedPlaces((prevSelectedPlaces) => {
      if (!prevSelectedPlaces.some((place) => place.label === object.label)) {
        selectedPlaceEvent([...prevSelectedPlaces, object])
        return [...prevSelectedPlaces, object];
      }
      selectedPlaceEvent(prevSelectedPlaces)
      return prevSelectedPlaces;
    });

    const newFormDataValues = {
      ...formDataValues,
      sumOfPrice: parseFloat(formDataValues.sumOfPrice) + parseFloat(object.pricing.price),
      numberOfTickets: parseInt(formDataValues.numberOfTickets) + 1
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleDeselectedObject = (object) => {
    // Remove the object from the selectedPlaces array
    setSelectedPlaces((prevSelectedPlaces) => {
        const selectedData = prevSelectedPlaces.filter((place) => place.label !== object.label)
        selectedPlaceEvent(selectedData)
        return selectedData
      }
    )

    const newFormDataValues = {
      ...formDataValues,
      sumOfPrice: parseFloat(formDataValues.sumOfPrice) - parseFloat(object.pricing.price),
      numberOfTickets: parseInt(formDataValues.numberOfTickets) - 1
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleSelectedObjects = (objects) => {
    console.log("handleSelectedObjects", objects.label)
  }

  const handleRadioChange = (event) => {
    const newFormDataValues = {
      ...formDataValues,
      ticketType: event.target.value,
    };

    setFormDataValues(newFormDataValues);
    formValues(newFormDataValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name === "numberOfTickets") {
      const newFormDataValues = {
        ...formDataValues,
        [name]: value,
        sumOfPrice: parseFloat(formDataValues.selectedCategoryPrice) * parseInt(value)
      };
      setFormDataValues(newFormDataValues);
      formValues(newFormDataValues);
    }
    else {
      const newFormDataValues = {
        ...formDataValues,
        [name]: value
      };
      setFormDataValues(newFormDataValues);
      formValues(newFormDataValues);
    }
  };
  const handleAutoCompleteEventChange = async (event, newValue) => {
    try {
      const selectedPricing = []
      newValue.eventSiege.map(el => {
        selectedPricing.push({
          category: el.name,
          price: el.price
        })
      })

      const newFormDataValues = {
        ...formDataValues,
        fromEvent: newValue ? newValue?.id : "",
        availableCategories: newValue.eventSiege,
        selectedCategory: "",
        selectedCategoryPrice: 0,
        isSelectetivePlaces: newValue.isSelectetivePlaces,
        seatIoId: newValue.seatIoId,
        pricing: selectedPricing,

        numberOfTickets: 0,
        sumOfPrice: 0
      };

      setFormDataValues(newFormDataValues);
      formValues(newFormDataValues);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAutoCompleteSeigeChange = async (event, newValue) => {
    try {
      const newFormDataValues = {
        ...formDataValues,
        selectedCategory: newValue ? newValue?.id : "",
        sumOfPrice: parseFloat(newValue.price) * parseInt(formDataValues.numberOfTickets),
        selectedCategoryPrice: parseFloat(newValue.price)
      };

      setFormDataValues(newFormDataValues);
      formValues(newFormDataValues);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Information</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              // multiple
              // value={selectedEvent}
              options={eventData}
              getOptionLabel={(option) => option?.title || ""}
              onChange={handleAutoCompleteEventChange}
              renderInput={(params) => <FormField {...params} label="Événement" />}
            />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={formDataValues?.ticketType}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel value="T" control={<Radio />} label="Ticket" />
                  <FormControlLabel value="I" control={<Radio />} label="Invitation" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Nom"
                name="firstName"
                value={formDataValues?.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="Prénom"
                name="lastName"
                value={formDataValues?.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="email"
                label="Email"
                name="email"
                value={formDataValues?.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                label="N° de téléphone"
                name="phone"
                value={formDataValues?.phone}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
            {
              !formDataValues?.isSelectetivePlaces && formDataValues?.seatIoId === "" ?
              <>
              <MDTypography variant="h5" mb={4}>Siège</MDTypography>
              <Grid container spacing={3}>
                {
                  formDataValues?.availableCategories && formDataValues?.availableCategories.length !== 0 ?
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        // multiple
                        // value={selectedEvent}
                        options={formDataValues?.availableCategories}
                        getOptionLabel={(option) => option?.name || ""}
                        onChange={handleAutoCompleteSeigeChange}
                        renderInput={(params) => <FormField {...params} label="Siége" />}
                      />
                    </Grid> :
                    <></>
                }
                <Grid item xs={12} sm={4}>
                  <FormField
                    type="number"
                    label="Nombre de tickets"
                    name="numberOfTickets"
                    value={formDataValues?.numberOfTickets}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid> 
              </>
              :
              <>
                <MDTypography variant="h5" mb={4}>Sélectionner via le plan</MDTypography>
                <div style={{ height: '80vh', width: '100%', marginBottom: '5vh' }} id="SeatsioSeatingChart">
                  <SeatsioSeatingChart
                    workspaceKey="2b467677-099f-48e3-ac2a-d4032fca5089"
                    event={formDataValues?.seatIoId}
                    pricing={formDataValues?.pricing}
                    priceFormatter={price => price + ' MAD'}
                    region="eu"
                    onObjectSelected={handleSelectedObject}
                    onObjectDeselected={handleDeselectedObject}
                    selectedObjects={handleSelectedObjects}
                    colorScheme="light"
                    colors={customColors}
                    objectColor = {(object, defaultColor) => {
                      if (object.selected) {
                        return "#00FF00";
                      } else {
                        return "#6C096C";
                      }
                    }}
                    showZoomOutButtonOnMobile={false}
                    onChartRendered={handleChartRendered}

                    showMinimap={true}
                    showFullScreenButton={true}

                    objectPopover={objectPopoverParams}
                  />
                </div>
              </>
            }

          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <Stack direction="row" spacing={1}>
              <Chip label="Prix total: " color="success" variant="outlined" />
              <MDTypography variant="p">{parseFloat(formDataValues?.sumOfPrice)} MAD</MDTypography>
            </Stack>
          </Stack>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
