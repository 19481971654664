/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { chartLineDataApi } from "api/statistiques";
import { formatedDate } from "utils/formattedDate";

let chartLineTableLabels = []
let chartLineTableData = []

const getchartLineInitialTableData = async () => {
    try {
        chartLineTableLabels = []
        chartLineTableData = []

        const response = await chartLineDataApi()
        chartLineTableLabels = response.dataLabels.map(el => el = formatedDate(el))
        chartLineTableData = response.dataTurnOverSets
    }
    catch (error) {
        console.log(error)
    }
}

await getchartLineInitialTableData()

const defaultLineChartTurnOverData = {
    labels: chartLineTableLabels,
    datasets: [
      {
        label: "MAD",
        color: "dark",
        data: chartLineTableData,
      },
    ],
};

export default defaultLineChartTurnOverData;
